import * as React from "react";

function ResetIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="#757575"
            {...props}
        >
            <defs>
                <filter id="prefix__a">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.603922 0 0 0 0 0.647059 0 0 0 0 0.694118 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g
                filter="url(#prefix__a)"
                transform="translate(-1045 -264)"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M1061 266.85a.49.49 0 01-.481.433h-.065l-1.51-.203c2.232 2.937 2.193 7.067-.094 9.96-2.287 2.892-6.216 3.783-9.478 2.147-3.26-1.637-4.99-5.367-4.17-8.994.82-3.63 3.972-6.197 7.602-6.193.27 0 .488.224.488.5s-.219.5-.488.5c-3.188-.001-5.951 2.264-6.654 5.454-.704 3.191.84 6.457 3.717 7.864 2.878 1.408 6.322.583 8.295-1.988a7.144 7.144 0 00-.062-8.74l-.081 1.933a.494.494 0 01-.484.477h-.023a.473.473 0 01-.339-.16.504.504 0 01-.126-.36l.13-3.103v-.164l.03-.06a.453.453 0 01.145-.153.476.476 0 01.069-.04l.065-.023h.192l2.855.39c.13.005.252.063.34.161a.505.505 0 01.127.362z"
                />
            </g>
        </svg>
    );
}

export default ResetIcon;
