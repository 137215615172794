import React, { useEffect, useState } from "react";
import DefectCard from "../DefectCard/DefectCard";
import GradeCard from "../GradeCard/GradeCard";
import * as dashboardStyles from "./defects.module.scss";
import defectCategories from "../../assets/data/comic-grading.json";
import ButtonAdd from "../ButtonAdd/ButtonAdd";
import { ICategoryItem, IDefectCategory } from "../../types/types";
import NoDefectsCard from "../NoDefectsCard/NoDefectsCard";
import ResetIcon from "../ResetIcon/ResetIcon";

export default function Defects() {
    const [addedCategories, setAddedCategories] = useState<IDefectCategory[]>(
        []
    );
    const [grade, setGrade] = useState(100);
    const [defects, setDefects] = useState([]);

    useEffect(() => {
        setDefectsList();
        calculateGrade();
    }, [addedCategories]);

    function calculateGrade() {
        const calculatedGrade = addedCategories.reduce((acc, current) => {
            const currentGrade = current.gradeVal;
            if (currentGrade > 0) {
                acc = Math.min(acc, currentGrade);
            }
            return acc;
        }, 100);
        setGrade(calculatedGrade);
    }

    function handleAddDefectClick() {
        const newDefect = {
            index: addedCategories.length,
            category: -1,
            categoryItems: [],
            defectNote: "",
            gradeVal: 0,
            selectedItemIndex: -2,
            categoryName: "",
            selectedItemName: "",
        };

        setAddedCategories((prevState) => {
            const addedCategoriesCopy = [...prevState];
            addedCategoriesCopy.push(newDefect);
            return addedCategoriesCopy;
        });
    }

    function handleRemoveClick(index: number) {
        setAddedCategories((prevState) => {
            let copy = [...prevState];
            copy.splice(index, 1);
            return copy;
        });
    }

    function handleGradeChange(cardIndex: number, itemIndex: number) {
        setAddedCategories((prevState) => {
            let copy = [...prevState];
            copy[cardIndex].selectedItemIndex = itemIndex;
            copy[cardIndex].gradeVal =
                defectCategories[copy[cardIndex].category].items[
                    itemIndex
                ].grade;
            copy[cardIndex].selectedItemName =
                defectCategories[copy[cardIndex].category].items[
                    itemIndex
                ].title;
            return copy;
        });
    }

    function setDefectsList() {
        setDefects(
            addedCategories.reduce((acc, curr) => {
                if (curr.gradeVal) {
                    const defect = {
                        title: curr.categoryName,
                        desc: curr.selectedItemName,
                    };
                    acc.push(defect);
                }
                return acc;
            }, [] as any)
        );
    }

    function handleDefectCategorySelect(event: any, cardIndex: number) {
        const categoryIndex = event.target.value;

        if (categoryIndex !== -1) {
            const categoryItems = defectCategories[categoryIndex].items.map(
                (ele: any, index: number) => ({
                    image: ele.img,
                    title: ele.title,
                    gradeVal: ele.grade,
                    index: index,
                })
            );

            setAddedCategories((prevState) => {
                let copy = [...prevState];
                copy[cardIndex].categoryItems = categoryItems;
                copy[cardIndex].category = categoryIndex;
                copy[cardIndex].categoryName =
                    defectCategories[categoryIndex].title;
                copy[cardIndex].selectedItemIndex = -2;
                copy[cardIndex].selectedItemName = "";
                copy[cardIndex].gradeVal = 0;
                return copy;
            });
        }
    }

    function handleDefectNoteChange(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        cardIndex: number
    ) {
        const { value } = event.target;
        const lenValue = Math.min(120, value.length);
        setAddedCategories((prevState) => {
            let copy = [...prevState];
            copy[cardIndex].defectNote = value.substring(0, lenValue);
            return copy;
        });
    }

    function handleReset() {
        setAddedCategories([]);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div
                    className={`col-md-12 col-lg-10 ${dashboardStyles.defectsMain}`}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <section id="tooselectedItemIndexl-description">
                                    <h1
                                        className={`${dashboardStyles.headingOne} `}
                                    >
                                        Comic Book Grading Tool
                                    </h1>
                                    <p>
                                        Based on the now defunct FREE-BIGS the
                                        FREE Examples-Based Internet Grading
                                        tool brought back to life. For each
                                        section choose the option that most
                                        closely matches the comic you are
                                        grading.
                                    </p>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <section
                                    id="how-to"
                                    className={`${dashboardStyles.howToDesc}`}
                                >
                                    <div
                                        className={`${dashboardStyles.headingTwo}`}
                                    >
                                        Defects
                                    </div>
                                    <p>
                                        Start by adding defects to get a grade
                                        for your book. Select a defect category
                                        and then the option that most closely
                                        resembles your book.
                                    </p>
                                </section>
                            </div>
                            <div
                                className={`col-md-4 col-sm-12 ${dashboardStyles.sectionReset}`}
                            >
                                <div
                                    className={`${dashboardStyles.resetContainer}`}
                                >
                                    <button onClick={handleReset}  className={`${dashboardStyles.startOver}`}>
                                        <ResetIcon /> {"\u00a0\u00a0"}
                                        Start Over
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {addedCategories.length ? (
                                addedCategories.map((ele, index) => (
                                    <DefectCard
                                        key={index}
                                        index={index}
                                        category={ele.category}
                                        categoryItems={ele.categoryItems}
                                        defectNote={ele.defectNote}
                                        gradeVal={ele.gradeVal}
                                        allDefectCategories={defectCategories}
                                        handleRemoveClick={handleRemoveClick}
                                        handleDefectCategorySelect={
                                            handleDefectCategorySelect
                                        }
                                        handleGradeChange={handleGradeChange}
                                        handleDefectNoteChange={
                                            handleDefectNoteChange
                                        }
                                        selectedItemIndex={
                                            ele.selectedItemIndex
                                        }
                                        addedCategories={addedCategories}
                                    />
                                ))
                            ) : (
                                <NoDefectsCard
                                    handleAddDefect={handleAddDefectClick}
                                />
                            )}
                        </div>
                        {addedCategories.length ? (
                            <ButtonAdd handleClick={handleAddDefectClick} />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div
                    className={`col-md-12 col-lg-2 ${dashboardStyles.defectsGradeContainer}`}
                >
                    <div
                        className={`${dashboardStyles.fixedGrade} container-fluid`}
                    >
                        <GradeCard grade={grade} defects={defects} />
                    </div>
                </div>
            </div>
        </div>
    );
}
