import React from "react";
import Layout from "../components/Layout";
import "../styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Defects from "../components/Defects/Defects";
export default function index() {
    return (
     
            <Layout title="Comic Book Grading Tool">
                <Defects />
            </Layout>

    );
}
