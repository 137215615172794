import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { IDefectCardListItemProps } from "../../types/types";
import * as defectLIStyles from "./defectCardListItem.module.scss";
import Comics from "../../assets/data/comic-grading.json";

export default function DefectCardListitem(props: IDefectCardListItemProps) {
    const { image: imageUrl, gradeVal, title, index, handleGrade, selectedItemIndex  } = props;

    return (
        <div    
            onClick={() => { handleGrade(index)}}
            className={`col-sm-4 col-md-2 d-flex flex-column align-items-center cursor-pointer`}
        >
            
                    <div className={`d-flex flex-column align-items-center`}>
                        <img
                            src={imageUrl}
                            alt={title}
                            style={{
                                width: '100%',
                            }}
                        />
                    </div>
                    <label className={`${defectLIStyles.labelTitle} d-flex justify-content-space align-items-center`}>
                        <Radio
                            color="primary"
                            className={`${defectLIStyles.radioButton}`}
                            style={{ left: 0, bottom: 0 }}
                            checked={ index === selectedItemIndex}
                        />
                        {title}
                    </label>
           
        </div>
    );
}
