import React from "react";
import * as buttonStyles from "./buttonAdd.module.scss";
import { IButtonAddProps } from "../../types/types";

export default function ButtonAdd(props: IButtonAddProps) {
    const { handleClick } = props;

    return (
        <button className={`${buttonStyles.buttonAdd}`} onClick={handleClick}>
            <span aria-hidden="true">+</span> Add Defect
        </button>
    );
}
