import React from "react";
import { IGradeCardProps } from "../../types/types";
import { FiPrinter} from "react-icons/fi"
import * as gradeCardStyles from "./gradeCard.module.scss"
import {getFinalGrade} from "../../utils/getFinalGrade"

export default function GradeCard(props : IGradeCardProps) {
    const { 
        grade, 
        defects } = props
    
    const gradeTitle = getFinalGrade(grade/10)

    const printSheet = (): void => {
        window.print()
    }
    
    return (
        <div id="grade-card" className={`card shadow ${gradeCardStyles.gradeCard}`}>
            <div className={`${gradeCardStyles.gradeSection}`}>
                <div>YOUR GRADE</div>
                <div className={`${gradeCardStyles.gradeRating}`}>{grade/10}</div>
                <div className="d-flex justify-content-between flex-row ">
                    <span className={`${gradeCardStyles.gradeTitle}`}>{gradeTitle.toUpperCase()}</span>
                    <button  className={`${gradeCardStyles.printSheet}`} onClick={printSheet}><FiPrinter />{"  "}Print</button>
                </div>
            </div>
            <div className={`${gradeCardStyles.bgSky}`}>
                {
                    defects.map( (defect, index)=>  <div className={`${gradeCardStyles.defectItem}`} key={index}>
                    <span className={`${gradeCardStyles.defectTitle}`}>{defect.title}</span>
                    <p className="mb-0">{defect.desc}</p>
                </div>)
                }
              
            </div>
        </div>
    );
}
