export function getFinalGrade (grade : number): string{
    if ( grade >=  9.9){
      return "Mint"
    } else if (grade >=9.8) {
      return "Near Mint/Mint"
    } else if (grade >=9.6) {
      return "Near Mint +"
    } else if (grade >=9.4) {
      return "Near Mint"
    } else if (grade >=9.2) {
      return "Near Mint -"
    } else if (grade >=9.0) {
      return "Very Fine/Near Mint"
    } else if (grade >=8.5) {
      return "Very Fine +"
    } else if (grade >=8.0) {
      return "Very Fine"
    } else if (grade >=7.5) {
      return "Very Fine -"
    } else if (grade >=7.0) {
      return "Fine/Very Fine"
    } else if (grade >=6.5) {
      return "Fine +"
    } else if (grade >=6.0) {
      return "Fine"
    } else if (grade >=5.5) {
      return "Fine -"
    } else if (grade >=5.0) {
      return "Very Good/Fine"
    } else if (grade >=4.5) {
      return "Very Good +"
    } else if (grade >=4.0) {
      return "Very Good"
    } else if (grade >=3.5) {
      return "Very Good -"
    } else if (grade >=3.0) {
      return "Good/Very Good"
    } else if (grade >=2.5) {
      return "Good +"
    } else if (grade >=2.0) {
      return "Good"
    } else if (grade >=1.8) {
      return "Good -"
    } else if (grade >=1.5) {
      return "Fair/Good"
    } else if (grade >=1.0) {
      return "Fair"
    } else if (grade >= .5) {
      return "Poor"
    } else {
      return "Gem Mint"
    }
}