import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import DefectCardListItem from "../DefectCardListItem/DefectCardListItem";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import { IDefectCardProps } from "../../types/types";
import defaultCardImg from "../../images/default.png";

import * as defectCardStyles from "./defectCard.module.scss";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 200,
    },
}));

export default function DefectCard(props: IDefectCardProps) {
    const {
        allDefectCategories,
        handleRemoveClick,
        index,
        handleDefectCategorySelect,
        category,
        categoryItems,
        defectNote,
        gradeVal,
        handleGradeChange,
        handleDefectNoteChange,
        selectedItemIndex,
        addedCategories,
    } = props;

    const [isNoteVisible, setIsNoteVisible] = useState(false);

    const classes = useStyles();

    const handleRemove = (): void => {
        handleRemoveClick(index);
    };

    function handleGrade(value : number) {
        handleGradeChange(index, value)
    }

    function handleNoteChange(
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        handleDefectNoteChange(e, index);
    }

    function toggleNoteVisiblity() {
        if (category > 0) setIsNoteVisible((prevState) => !prevState);
    }

    return (
        <div className={`card ${defectCardStyles.defectCard} shadow`}>
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                shrink
                                id="demo-simple-select-label"
                                style={{ fontSize: 14, textTransform: 'uppercase' }}
                            >
                                Defect Category
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={category}
                                onChange={(e) => {
                                    handleDefectCategorySelect(e, index);
                                }}
                            >
                            
                                {allDefectCategories
                                    ? allDefectCategories.reduce(
                                          (
                                              acc: any,
                                              curr: any,
                                              indexVal: number
                                          ) => {
                                              const result = addedCategories.find(
                                                  (cat) => {
                                                      if (
                                                          cat.category ===
                                                          category
                                                      )
                                                          return false;

                                                      if (
                                                          cat.category ===
                                                          indexVal
                                                      )
                                                          return true;
                                                  }
                                              );
                                              if (!result) {
                                                  acc.push(
                                                      <MenuItem
                                                          value={indexVal}
                                                          key={indexVal}
                                                      >
                                                          {curr.title}{" "}
                                                      </MenuItem>
                                                  );
                                              }
                                              return acc;
                                          },
                                          []
                                      )
                                    : ""}
                                <MenuItem value={-1}>Select</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <button
                        className={`${defectCardStyles.defectRemove}`}
                        onClick={handleRemove}
                    >
                        <BiTrash /> Remove
                    </button>
                </div>
                <div
                    id="defect-item-container"
                    className={`${defectCardStyles.defectItemContainer} container-fluid`}
                >
                    {category < 0 ? (
                        <RadioGroup key="empty">
                            <div className="row">
                                {[1, 1, 1, 1].map((ele,indexNum) => (
                                    <DefectCardListItem
                                        image={defaultCardImg}
                                        gradeVal={-1}
                                        title="Defect"
                                        index={-1}
                                        key={indexNum}
                                        handleGrade={ ()=>{}}
                                    />
                                ))}
                            </div>
                        </RadioGroup>
                    ) : (
                        <RadioGroup
                            value={selectedItemIndex}
                            key="notempty"
                        >
                            <div className="row">
                                {categoryItems.map((item, index) => (
                                    <DefectCardListItem
                                        key={item.title}
                                        image={item.image}
                                        title={item.title}
                                        gradeVal={item.gradeVal}
                                        index={item.index}
                                        handleGrade = { handleGrade}
                                        selectedItemIndex={selectedItemIndex}
                                    />
                                ))}
                            </div>
                        </RadioGroup>
                    )}
                </div>
                <div className="defect-note" style={{ paddingTop: 50 }}>
                    <label
                        htmlFor="defect-note"
                        className={`${defectCardStyles.defectNoteTitle}`}
                        onClick={toggleNoteVisiblity}
                    >
                        DEFECT NOTE{" "}
                        {isNoteVisible ? <BsChevronUp /> : <BsChevronDown />}
                    </label>
                    {category < 0 ? (
                        " "
                    ) : isNoteVisible ? (
                        <div style={{ width: "100%" }}>
                            <TextField
                                onChange={handleNoteChange}
                                id="defect-note"
                                style={{ width: "100%" }}
                                value={defectNote}
                            />
                            <div className="d-flex justify-content-end">
                                <span>{defectNote.length}/120</span>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
}

// class="col-sm-3 d-flex flex-column align-items-center align-items-sm-start"
