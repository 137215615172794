import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { INoDefectsCardProps } from "../../types/types";
import ButtonAdd from "../ButtonAdd/ButtonAdd";
import * as noDefectsCardStyles from "./noDefectsCard.module.scss";

export default function NoDefectsCard(props: INoDefectsCardProps) {
    const { handleAddDefect } = props;
    return (
        <div className={`card shadow mb-4`}>
            <div className="card-body">
                <div className="d-flex flex-column align-items-center">
                    <div className={`${noDefectsCardStyles.headingTwo}`}>
                        No Defects Reported
                    </div>
                    <div className={`${noDefectsCardStyles.superHero}`}>
                        <StaticImage
                            src="../../images/superhero.svg"
                            alt="No defects"
                        />
                    </div>
                    <div>Get started by adding a defect</div>
                    <div>
                        <ButtonAdd handleClick={handleAddDefect} />
                    </div>
                </div>
            </div>
        </div>
    );
}
